import {FilterItem, SearchItem, BranchTypes} from "@/types/pages/Branch/Branch.type";

export const SEARCH_ITEMS: SearchItem[] = [
    {
        name: 'common.form.name',
        value: 'name',
        short: 'common.form.name'
    },
];

export const FILTER_ITEMS = (): FilterItem => ({
    region_id: "",
    is_atm: BranchTypes.branch
});
