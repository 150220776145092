<template>
  <div class="branch-filter">

    <AppSelect
        v-model="filter.region_id"
        class="branch-filter__drop"
        :items="selectedRegions"
        item-value="id"
        item-name="name_uz"
        theme="gray"
        size="medium"
        @input="emit('set:filter', {key: 'region_id', value: $event})"
    />

    <div class="branch-filter__search">
      <SearchForm
          :value="filter[categoryInternal]"
          :type="categoryInternal"
          :items="searchItems"
          fixed theme="application"
          @input="delay(() => emit('set:filter',{key: categoryInternal, value: $event}))"
      />
    </div>
    <div class="app-spacer"/>
    <AppButton
        class="admin-application-filter__button admin-application-filter__item"
        theme="success-flat"
        @click="emit('click:create')"
    >
      {{ $t("common.add") }}
    </AppButton>

  </div>
</template>

<script>
import SearchForm from "@/components/partials/SearchForm.vue";
import AppButton from "@/components/partials/AppButton.vue";
import AppSelect from "@/components/partials/AppSelect.vue";
import ListUtils from "@/mixins/ListUtils";

export default {
  name: "BranchFilters",
  mixins: [ListUtils],
  emits: [ "input", "set:filter", "click:create" ],
  components: {
    AppSelect,
    AppButton,
    SearchForm
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    categoryInternal: {
      type: String,
      required: true,
      default: ''
    },
    searchItems: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedRegions: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  methods: {
    emit(name, $value) {
      this.$emit(name, $value)
    }
  }
}
</script>
