<template>
  <div>

    <span>{{ missed }}-{{ limit }} {{ $t("common.forth", {count}) }}</span>

    <b-pagination
        class="pagination"
        next-class="prev"
        prev-class='next'
        prev-text="" next-text=""
        v-model="pagination.page"
        :total-rows="count"
        :per-page="pagination.limit"
        aria-controls="my-table"
        @input="emit('update:page')"
    />

  </div>
</template>

<script>
export default {
  name: "AppPagination",
  emits: ['update:page', 'input'],
  props: {
    count: {
      type: [String, Number],
      required: true,
      default: 0
    },
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  model: {
    prop: 'value',
    event: "input"
  },
  computed: {
    pagination: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    missed() {
      return this.pagination.page * this.pagination.limit - this.pagination.limit + 1;
    },
    limit() {
      return Math.min(this.count, this.pagination.page * this.pagination.limit);
    },
  },
  methods: {
    emit(name) {
      this.$emit(name, this.pagination);
    }
  }
}
</script>
