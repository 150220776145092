export type SearchItem = {
    name: string;
    value: string;
    short: string;
};

export type FilterItem = {
    region_id: string;
    is_atm: number;
};

export enum BranchTypes {
    branch = 0,
    atm = 1
}
