<template>
    <div class="script-item">

        <div class="script-item__actions">
            <slot name="additional-actions"/>

            <AppButton :theme="isOpen ? 'primary-outline-a' : ''" square size="small" @click="emit('open-menu:action')">
                <AppIcon :icon="isOpen ? 'chevron-up' : 'chevron-down'"/>
            </AppButton>

            <div class="script-item__button-wrap" v-clickaway="close" v-if="actions.length">

                <AppButton class="script-item__button" theme="primary-outline" square size="small" @click="toggle">
                    <AppIcon icon="dots-horizontal"/>
                </AppButton>

                <transition name="dialer">

                    <div
                        v-show="opened"
                        class="script-item__menu-actions script-item__menu-actions--direction-bottom"
                    >

                        <action-button
                            v-if="actions.includes('edit')"
                            icon="edit-alt"
                            text="common.table.edit"
                            @on:click="emit('edit:action')"
                        />
                        <action-button
                            v-if="actions.includes('copy')"
                            icon="copy"
                            text="common.table.copy"
                            @on:click="emit('copy:action')"
                        />
                        <action-button
                            v-if="actions.includes('remove')"
                            icon="trash-alt"
                            text="common.table.delete"
                            @on:click="emit('remove:action')"
                        />

                    </div>

                </transition>
            </div>
        </div>

    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";
import ActionButton from "@/components/admin/Branch/AccordionHelpers/ActionButton.vue";
import AppButton from "@/components/partials/AppButton.vue";
import clickaway from "@/directives/clickaway";

export default {
    components: {
        AppButton,
        AppIcon,
        ActionButton
    },
    directives: {clickaway},
    props: {
        actions: Array,
        isOpen: Boolean
    },
    data: () => ({
        opened: false
    }),
    methods: {
        toggle() {
            if (this.opened) this.close();

            this.open();
        },
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
        },
        emit(name) {
            this.$emit(name);
        }
    }
}
</script>

<style src="@/assets/scss/modules/components/admin/Branch/AccordionHelpers/HeaderMenuActions.scss" lang="scss" scoped></style>
