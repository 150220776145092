<script>
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
  emits: ["on:click"],
  props: {
    title: {
      type: [String, Object],
      required: true,
      default: "",
    },
    value: {
      type: [Number, String],
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mixins: [translationExists],
  computed: {
    formattedTitle() {
      return this.getTranslationOrKey(this.title)
    },
    formattedValue() {
      return this.value || this.$t("common.emptyData")
    }
  },
  methods: {
    handleClick() {
      if (this.clickable) {
        this.$emit("on:click");
      }
    }
  },
  render(h) {
    return h('div', { class: 'column--size-' + this.size }, [
      h('p', { class: 'column-header' }, this.formattedTitle),
      h('p', {
        class: ['column-body', this.clickable && this.value ? 'clickable' : ''].join(' '),
        on: {
          click: this.handleClick
        }
      }, this.formattedValue)
    ]);
  }
}

</script>
