<template>
  <div class="atm-accordion">
    <!-- Accordion header -->
    <div class="atm-accordion__header">
      <div class="atm-accordion__header-info">
        <accordion-column
          title="common.table.number"
          :value="item.id"
          size="xs"
        />
        <accordion-column
          title="branch.bo"
          :value="item.name"
          size="lg"
        />
        <accordion-column
          title="common.table.name"
          :value="item.region?.name_uz"
          size="md"
          :clickable="true"
        />
        <accordion-column
          title="common.shortPhoneNumber"
          :value="item.mfo"
          size="sm"
        />
        <accordion-column
          title="common.paymentSystem"
          :value="item.tin"
          size="sm"
        />
        <accordion-column
          title="common.address"
          :value="item.email"
          size="lg"
        />
      </div>

      <div class="atm-accordion__header-actions">
        <header-menu-actions
          :actions="['edit', 'remove']"
          :is-open="isOpen"
          @edit:action="emit('edit:action')"
          @remove:action="emit('remove:action')"
          @open-menu:action="toggleAccordion"
        />
      </div>
    </div>

    <!-- Accordion content -->
    <div
      v-show="isOpen"
      class="atm-accordion__content"
    >
      <div class="atm-accordion__content-info">
        <accordion-column
          title="common.referencePoint"
          :value="item.approximately"
          size="lg"
        />
        <accordion-column
          title="common.coordinates"
          :value="coordinates"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AccordionColumn from "@/components/admin/Branch/AccordionHelpers/Column.vue";
import HeaderMenuActions from "@/components/admin/Branch/AccordionHelpers/HeaderMenuActions.vue";

export default {
    components: {
        HeaderMenuActions,
        AccordionColumn
    },
    computed: {
        coordinates() {
            if (!this.item?.coordinate_latitude || !this.item?.coordinate_longitude) {
                return this.$t("common.emptyData");
            }

            return this.item.coordinate_latitude + ", " + this.item.coordinate_longitude;
        },
    },
    props: {
        item: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        emit(name) {
            this.$emit(name, this.item.id);
        },
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style src="@/assets/scss/modules/components/admin/Branch/AtmAccordion.scss" lang="scss"></style>
