<template>
  <main class="app-admin-page">
    <section class="branch">
      <branch-header :count="count" />

      <branch-filters
        v-model="filter"
        :category-internal="categoryInternal"
        :search-items="searchItems"
        :selected-regions="selectedRegions"
        @set:filter="setFilter"
        @click:create="create"
      />

      <branch-content
        v-model="activeTabKey"
        :tab-labels="tabLabels"
        :is-empty-data="isEmptyData"
        :loading="isLoading"
        :branches="branchOrSkeleton"
        @update:tab="changeTab"
        @set:filter="setFilter"
        @edit:action="edit"
        @remove:action="remove"
      />

      <app-pagination
        v-if="showPagination"
        v-model="pagination"
        :count="count"
        class="d-flex justify-content-between align-items-center mt-20"
        @update:page="fetch"
      />
    </section>
  </main>
</template>

<script>
import {get, del} from "@/helpers/api";
import {FILTER_ITEMS, SEARCH_ITEMS} from "@/data/pages/Branch/Branch.ts";
import {PAGINATION} from "@/data/pages/Common";
import BranchHeader from "@/components/admin/Branch/BranchHeader.vue";
import BranchFilters from "@/components/admin/Branch/BranchFilters.vue";
import BranchContent from "@/components/admin/Branch/BranchContent.vue";
import AppPagination from "@/components/partials/common/Pagination.vue";
import {BranchTypes} from "@/types/pages/Branch/Branch.type";
import ConfirmController from "@/services/ConfirmController";

export default {
  name: "Branch",
  components: {
    AppPagination,
    BranchContent,
    BranchFilters,
    BranchHeader
  },
  data() {
    return {
      isLoading: true,
      activeTabKey: "branch",
      count: 0,
      categoryInternal: "name",
      branches: [],
      regions: [],
      searchItems: SEARCH_ITEMS,
      filter: new FILTER_ITEMS(),
      pagination: new PAGINATION(),
    };
  },
  computed: {
    branchOrSkeleton() {
      if (this.isLoading) {
        return this.skeletonList
      }
      return this.branches
    },
    isEmptyData() {
      return !this.isLoading && this.branches.length === 0;
    },
    skip() {
      return (this.pagination.page - 1) * 10;
    },
    limit() {
      return Math.min(this.count, this.pagination.page * this.pagination.limit);
    },
    selectedRegions() {
      return [{name_uz: "common.all", id: ""}, ...this.regions];
    },
    showPagination() {
      return this.count > this.pagination.limit && !this.isEmptyData;
    },
    tabLabels() {
      return {
        branch: this.$t("branch.show.tabs.branch"),
        atm: this.$t("branch.show.tabs.atm")
      };
    },
    skeletonList() {
      const list = []
      for (let index = 0; index < 10; index++) {
        list.push({
          id: index,
          skeleton: true,
        })
      }
      return list
    }
  },
  methods: {
    setFilter({key, value}) {
      this.filter[key] = value;
      this.fetch();
    },
    changeTab() {
      const query = {...this.$route.query, type: this.activeTabKey};
      this.$router.replace({path: "branches", query});
      this.filter.is_atm = BranchTypes[this.activeTabKey];
      this.pagination.page = 1;
      this.fetch();
    },
    async fetch() {
      this.isLoading = true;

      const query = {...this.filter, limit: this.pagination.limit, skip: this.skip, page: this.pagination.page};
        const queryString = Object.entries(query)
            .reduce((acc, [key, value]) => {
                if (value || value === 0) {
                    acc.push(`${key}=${value}`);
                }

                return acc;
            }, [])
            .join("&");


      try {
        const response = await get(`/branches?${queryString}`);

        if (response.data.success) {
          this.branches = response.data.data.result;
          this.count = response.data.data.count;
          // window.scrollTo({
          //   top: 0,
          //   behavior: "smooth"
          // });
        }
        this.isLoading = false;

      } catch (e) {
        this.$store.commit("notifications/error", this.$t("errors.someThingWentWrong"));
        this.isLoading = false;
      }
    },
    async loadRegions() {
      try {
        this.isLoading = true;
        const response = await get("/regions");

        if (response.status === 200) {
          this.regions = response.data.data;
        }
      } catch (e) {
        this.$store.commit("notifications/error", this.$t("errors.someThingWentWrong"));
      }
    },
    create() {
      this.$router.push({path: "/branches/create", query: {type: this.activeTabKey}});
    },
    edit(id) {
      this.$router.push({path: `/branches/edit/${id}`, params: {id}, query: {type: this.activeTabKey}});
    },
    remove(id) {
      const confirm = new ConfirmController();

      this.isLoading = true;

      confirm.call().then(async() => {
          try {
              await del(`/branches/${id}`);
              this.$store.commit("notifications/add", {
                  type: "success",
                  message: "requestsStatuses.success.removed"
              });
          } catch (e) {
              this.$store.commit("notifications/error", "errors.failedDeleteBranch");
          } finally {
              this.fetch().finally(() => {
                  this.isLoading = false;
              });
          }
      });
    },
  },
  created() {
    if (this.$route.query.type) {
      this.activeTabKey = this.$route.query.type;
      this.filter.is_atm = BranchTypes[this.activeTabKey];
    }

    this.fetch();
    this.loadRegions();
  },
};
</script>

<style src="@/assets/scss/modules/pages/Branch/Branch.scss" lang="scss"></style>
