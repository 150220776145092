<template>
  <a-tabs
    class="branch-tabs"
    :class="loading && 'branch-tabs--readonly'"
    id="test"
    v-model="activeTabKey"
    :animated="false"
    @change="emit('update:tab')"
  >
    <a-tab-pane
      v-for="tab in ['branch', 'atm']"
      :key="tab"
      :tab="tabLabels[tab]"
      class="branch-tabs__item"
    >
      <component
        v-if="loading"
        :is="`${tab}-list-skeleton`"
      />
      <!-- Possible components are BranchAccordion or AtmAccordion -->
      <component
        v-else
        :is="`${tab}-accordion`"
        v-show="!isEmptyData"
        v-for="(branch, index) in branches"
        :key="branch.id"
        class="mb-1"
        :item="branch"
        :index="index"
        :type="tab"
        @set:filter="emit('set:filter', $event)"
        @edit:action="emit('edit:action', $event)"
        @remove:action="emit('remove:action', $event)"
      />

      <EmptyData v-show="!loading && isEmptyData" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import EmptyData from "@/components/partials/common/EmptyData.vue";
import BranchAccordion from "@/components/admin/Branch/BranchAccordion.vue";
import AtmAccordion from "@/components/admin/Branch/AtmAccordion.vue";
import BranchListSkeleton from "@/components/skeletons/branch-list.vue";
import AtmListSkeleton from "@/components/skeletons/atm-list.vue";

export default {
  name: "BranchContent",
  emits: ["update:tab", "edit:action", "remove:action", "input"],
  components: {
      AtmListSkeleton,
      BranchListSkeleton,
    EmptyData,
    BranchAccordion,
    AtmAccordion
  },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    tabLabels: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isEmptyData: {
      type: Boolean,
      required: true,
      default: false
    },
    branches: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeTabKey: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    emit(name, $value) {
      this.$emit(name, $value);
    }
  }
};
</script>
