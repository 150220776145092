<template>
  <div class="branch-accordion">
    <!-- Accordion header -->
    <div class="branch-accordion__header">
      <div class="branch-accordion__header-info">
        <accordion-column
          title="common.table.number"
          :value="item.id"
          size="xs"
        />
        <accordion-column
          title="common.table.name"
          :value="item.name"
          size="lg"
        />
        <accordion-column
          class="cursor-pointer"
          title="common.table.region"
          :value="item.region?.name_uz"
          size="md"
          :clickable="true"
          @on:click="emit('set:filter', {key: 'region_id', value: item.region?.id})"
        />
        <accordion-column
          title="common.table.MFO"
          :value="item.mfo"
          size="sm"
        />
        <accordion-column
          title="common.tin"
          :value="item.tin"
          size="sm"
        />
        <accordion-column
          title="common.email"
          :value="item.email"
          size="lg"
        />
        <accordion-column
          title="common.address"
          :value="item.address"
          size="xl"
        />
      </div>

      <div class="branch-accordion__header-actions">
        <header-menu-actions
          :actions="['edit', 'remove']"
          :is-open="isOpen"
          @edit:action="emit('edit:action', item.id)"
          @remove:action="emit('remove:action', item.id)"
          @open-menu:action="toggleAccordion"
        />
      </div>
    </div>

    <!-- Accordion content -->
    <div
      v-show="isOpen"
      class="branch-accordion__content"
    >
      <div class="branch-accordion__content-info">
        <accordion-column
          title="common.referencePoint"
          :value="item.approximately"
          size="lg"
        />
        <accordion-column
          title="common.postcode"
          :value="item.postal_code"
          size="md"
        />
        <accordion-column
          :title="{ text: 'common.shortPhoneNumberWithValue', keys: { value: 1 } }"
          :value="item.phones?.[0]?.phone"
          size="sm"
          active=""
        />
        <accordion-column
          :title="{ text: 'common.shortPhoneNumberWithValue', keys: { value: 2 } }"
          :value="item.phones?.[1]?.phone"
          size="sm"
        />
        <accordion-column
          title="common.fax"
          :value="item.phones?.[2]?.phone"
          size="lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AccordionColumn from "@/components/admin/Branch/AccordionHelpers/Column.vue";
import HeaderMenuActions from "@/components/admin/Branch/AccordionHelpers/HeaderMenuActions.vue";

export default {
  emits: ["set:filter", "edit:action", "remove:action"],
  components: {
    HeaderMenuActions,
    AccordionColumn
  },
  computed: {
    coordinates() {
      if (!this.item?.coordinate_latitude || !this.item?.coordinate_longitude) {
        return this.$t("common.emptyData");
      }

      return this.item.coordinate_latitude + ", " + this.item.coordinate_longitude;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    emit(name, $value) {
      this.$emit(name, $value);
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style src="@/assets/scss/modules/components/admin/Branch/BranchAccordion.scss" lang="scss"></style>
