<template>
  <div class="branch-header">
    <h1 class="branch-header__title">{{ $t("branch.index") }}</h1>
    <div class="branch-header__count"><span>{{ $t("common.table.found") }}: {{ count }}</span></div>
  </div>
</template>

<script>
export default {
  name: "BranchHeader",
  props: {
    count: {
      type: [String, Number],
      required: true,
      default: 0
    }
  }
}
</script>
