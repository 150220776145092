<template>
  <div>
    <div
      class="branch-accordion mb-1"
      v-for="i in limit"
      :key="i"
    >
      <div class="branch-accordion__header">
        <div class="branch-accordion__header-info">
          <div class="column--size-xs">
            <p class="column-header">
              {{ $t('common.table.number') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-lg">
            <p class="column-header">
              {{ $t('common.table.name') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-md">
            <p class="column-header">
              {{ $t('common.table.region') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-sm">
            <p class="column-header">
              {{ $t('common.table.MFO') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-sm">
            <p class="column-header">
              {{ $t('common.tin') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-lg">
            <p class="column-header">
              {{ $t('common.email') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
          <div class="column--size-xl">
            <p class="column-header">
              {{ $t('common.address') }}
            </p>
            <div class="placeholder placeholder--medium w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    name: "BranchListSkeleton",
    props: {
        limit: {
            type: Number,
            default: 10
        }
    }
};
</script>
